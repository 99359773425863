import React, { useState } from "react";
import { Link } from 'react-router-dom'; // Importe o componente Link
import logo from '../../../../assets/svg/logo.svg';
import calendar from '../../../../assets/svg/calendar.svg';
import map from '../../../../assets/svg/map.svg';
import smile from '../../../../assets/svg/smile.svg';
import search from '../../../../assets/svg/search.svg';
import logout from '../../../../assets/svg/logout.svg';

import '../css/navbar.css';

export default function Navbar(itenNav) {

    const [isOpen, setIsOpen] = useState(false);

    const toggleNavbar = () => {
        setIsOpen(!isOpen);
    };

    const handleLogout = () => {
        // Limpar o Session Storage
        sessionStorage.clear();
        // Redirecionar para a página inicial
        window.location.href = '/'; // Redirecionamento direto
    };



    return (
        <div id="navbar">
            <nav className="navbar navbar-expand-sm">
                <div className="container">
                    <Link to="/"> {/* Modifique o link aqui */}
                        <img src={logo} className="logo" alt="logo" />
                    </Link>
                    <form className="d-flex">
                        <div className="input-group">
                            <span className="input-group-text">
                                <img src={search} className="icon" alt="icone para heje" />
                            </span>
                            <input className="form-control" type="text" placeholder="Pesquisar" />
                        </div>
                    </form>
                    <button className="navbar-toggler" type="button" onClick={toggleNavbar}>
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className={"collapse navbar-collapse" + (isOpen ? " show" : "")} id="mynavbar">
                        <ul className="navbar-nav ms-auto">
                            <li className={`nav-item ${itenNav.activeLocation === 'home' ? 'nav-item-active' : ''}`}>
                                <Link className="nav-link" to="/">
                                    <img src={calendar} className="icon" alt="icone para pagina Inicial" /><br />
                                    Hoje
                                </Link>
                            </li>
                            <li className={`nav-item ${itenNav.activeLocation === 'explorar' ? 'nav-item-active' : ''}`}>
                                <Link className="nav-link" to="/explorar"> {/* Modifique o link aqui */}
                                    <img src={map} className="icon" alt="icone para Explorar" /><br />
                                    Explorar
                                </Link>
                            </li>
                            <li className={`nav-item ${itenNav.activeLocation === 'perfil' ? 'nav-item-active' : ''}`}>
                                <Link className="nav-link" to="/perfil">
                                    <img src={smile} className="icon" alt="icone para o perfil" /><br />
                                    Perfil<br />
                                </Link>

                            </li>
                            <li className="nav-item">
                                <button className="nav-link" onClick={handleLogout}>
                                    <img src={logout} className="icon" alt="icone para Sair" /><br />
                                    Sair
                                </button>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        </div>
    );
}
