import React from 'react';
import icon from '../../../../assets/svg/Icon.svg';

const ProfileCardTip = ({ point }) => {
    return (
        <>
            <div className="card card-tip">
                <div className="card-body box-profile-text-tip">
                    <span className='profile-text-tip'>
                        Assista a mais papos para ganhar a sua 3ª medalha!
                    </span>
                    <div className="linha">
                        {[...Array(5)].map((_, index) => (
                            (index < point) ? <div className="quadrado profile-tip-active" key={index}></div> : <div className="quadrado" key={index}></div>
                        ))}
                    </div>
                </div>
            </div>
            <div className="card card-pass">
                <div className="card-body box-profile-text-pass">
                    <span>
                        Tem 15 minutos? <br />
                        Assista a um papo!
                    </span>
                    <img src={icon} alt='icon' className="box-profile-text-pass-icon" />
                </div>
            </div>
        </>
    );
};

export default ProfileCardTip;
