import React from 'react';
import SpinLoader from './spinLoader';
import './css/loader.css';

const Loader = () => {
    return (
        <div className="loader-img">
            <SpinLoader />
        </div>
    );
};

export default Loader;
