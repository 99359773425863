import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import '../css/profileCard.css';
import foto from '../../../../assets/svg/foto.svg';
import pta from '../../../../assets/svg/pontucacao_ativo.svg';
import pti from '../../../../assets/svg/pontucacao_inativo.svg';
import imgPapos from '../../../../assets/svg/message-square.svg';
import imgFavortitos from '../../../../assets/svg/bookmark.svg';


const ProfileCard = ({ photo, name, role, point }) => {

    return (
        <div className="card">
            <div className="card-header profile-header">
                <img src={foto} alt="Profile" className="profile-photo" />
            </div>
            <div className="card-body">
                <div className="profile-card">
                    <div className="profile-info">
                        <h2 className="profile-name">{name}</h2>
                        <p className="profile-role">{role}</p>
                    </div>
                    <div className="star-rating">
                        {[...Array(5)].map((_, index) => (
                            <img
                                key={index}
                                src={index < point ? pta : pti}
                                alt="Star"
                                className="star-icon"
                            />
                        ))}
                    </div>
                </div>
            </div>
            <hr className="hr-divider" />
            <div className="card-footer profile-footer">
                <div className="footer-item">
                    <Link to="/papos">
                        <img src={imgPapos} alt="Meus Papos" className="icon-image" />
                        Meus Papos
                    </Link>
                </div>
                <div className="footer-item">
                    <img src={imgFavortitos} alt="Favoritos" className="icon-image" />
                    Favoritos
                </div>
            </div>
        </div>
    );
};

export default ProfileCard;

