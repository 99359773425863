import React from 'react';
import heart from '../../../../assets/svg/heart.svg';
import bookOpen from '../../../../assets/svg/book-open.svg';

const UserReact = () => {
    return (
        <>
            <div className="col-3 col-sm-2 col-md-2 col-lg-1">
                <img src={heart} alt='like' className='img-heart' />
            </div>
            <div className="col-9 col-sm-10 col-md-8 col-lg-3 img-see-later">
                <img src={bookOpen} alt='like' />
                <span>Assistir mais tarde</span>
            </div>
        </>
    );
};

export default UserReact;