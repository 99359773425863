import React from 'react';
import CategoryCard from '../categoryCard';
import '../css/todayCard.css';


const CategoryListPage = () => {
    const categoriesArray = [
        { "text": "Trabalho Remoto", "color": "#005CE7", },
        { "text": "Marketing", "color": "#8A3D91", },
        { "text": "Criatividade", "color": "#96C11A", },
        { "text": "Bem-estar", "color": "#404040", },
        { "text": "Gestão", "color": "#5DD0C0", },
        { "text": "Comunicação", "color": "#E5522D", },
        { "text": "Complice", "color": "#FBC334", },
        { "text": "Técnoligia", "color": "#EC55B4", }
    ];

    return (
        <div className="container today-container">
            <div className="header-container">
                <h2 className="my-10">Categorias</h2>
                <hr className="line" />
            </div>
            <div className="row">
                <CategoryCard categories={categoriesArray} />
            </div>
        </div>
    );
}

export default CategoryListPage;
