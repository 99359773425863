import React, { useState, useEffect } from "react";
import Navbar from "./papo/components/navbar";
import apiClient from '../utils/api/index';
import ProfileCard from "./papo/components/profileCard";
import ProfileCardTip from "./papo/components/profileCardTip";
import todayListData from './papo/data/todayList.json'; // Import the JSON file for TodayListPage

import TodayListPage from "./papo/components/todayListPage";
import Loader from '../utils/loader/index';
import ForYouListPage from "./papo/components/forYouListPage";

function Index(props) {
    const [userToken, setUserToken] = useState(null);
    const [userkey, setUserKey] = useState(null);
    const [timer] = useState(2000);
    const [showLoader, setShowLoader] = useState(true);
    const [appList, setAppList] = useState(props.props); // Using existing appList for ForYouListPage
    const [todayList] = useState(todayListData); // Initialize with JSON data for TodayListPage

    useEffect(() => {
        setUserToken(sessionStorage.getItem('userToken') ?? '');
        setUserKey(sessionStorage.getItem('userkey') ?? '');
        apiClient.setAppkey(userkey);
        apiClient.setToken(userToken);
        apiClient.setKeyName('x-userkey');
        apiClient.get('all/apps')
            .then(data => {
                setAppList(data.data);
            })
            .catch(error => {
                console.error('Error fetching data:', error);
                // Handle error or cleanup as needed
            });
    }, [userToken, userkey]);

    useEffect(() => {
        const timerId = setTimeout(() => {
            setShowLoader(false);
        }, timer);
        return () => clearTimeout(timerId);
    }, [timer]);

    // Decode token function remains unchanged

    return (
        <div className="app">
            <Navbar activeLocation="home" />
            <div className="container">
                <div className="row">
                    <div className="col-12 col-sm-12 col-md-4 col-lg-3">
                        {showLoader ? <Loader /> :
                            <ProfileCard
                                photo="foto.svg"
                                name="Enzo Souza"
                                role="Community Manager"
                                point={2}
                            />
                        }
                        {showLoader ? <Loader /> : <ProfileCardTip point={2} />}
                    </div>
                    <div className="col-12 col-sm-12 col-md-4 col-lg-9">
                        {showLoader ? <Loader /> : <TodayListPage todayList={todayList} />} {/* Use todayList for TodayListPage */}
                        {showLoader ? <Loader /> : (appList ? <ForYouListPage appList={appList} /> : "")}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Index;
