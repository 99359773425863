// src/routes/index.js
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import MasterRoutes from '../yeshapps/master/routes/index';
import StatPageRoutes from '../yeshapps/index'
import Explorer from '../yeshapps/explorer';
import ChatRoute from '../yeshapps/chatRoute';
import Profile from '../yeshapps/profile';
/* import AdminRoutes from './yeshapps/admin/Index'; */

function Index() {
    return (
        <Routes>
            {/* Rotas raiz */}
            <Route path="/" element={<StatPageRoutes />} />
            <Route path="/master/*" element={<MasterRoutes />} />
            <Route path="/explorar" element={<Explorer />} />
            <Route path="/papos" element={<ChatRoute />} />
            <Route path="/perfil" element={<Profile />} />

            {/* Rota para a página ChatRoute */}
            {/* <Route path="/admin/*" element={<AdminRoutes />} /> */}
            {/* Adicione outras rotas raiz conforme necessário */}
        </Routes>
    );
}

export default Index;
