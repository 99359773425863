import React from 'react';
import App from './app/index';
import { createRoot } from 'react-dom/client';

import './utils/themeswitcher/dark-theme.css'
import './utils/themeswitcher/light-theme.css'
import './index.css'
const root = createRoot(document.getElementById('root'));
root.render(
  
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
