import React from 'react';
import PlyrPlayer from '../plyrPlayer/index';
import UserReact from '../userReact';
import '../css/boxVideo.css';

const BoxVideo = () => {
    return (

        <div className='card card-video'>
            <div className='container box-video-container'>
                <PlyrPlayer provider="youtube" source="https://www.youtube.com/watch?v=BfWdsqVE5NU" />
            </div>
            <div className='container'>
                <div className='row box-video-descrption'>
                    <div className="col-12 col-sm-12 col-md-12 col-lg-8"
                    >
                        <h1>
                            Liderança no Trabalho Remoto
                        </h1>
                    </div><br />
                    <UserReact />
                    <div className="col-12 col-sm-12 col-md-12 col-lg-8"
                    >
                        <h2>
                            Episódio 02: Comunicação efetiva em ambientes virtuais
                        </h2>
                    </div><br />
                    <div className="col-12 col-sm-12 col-md-12 col-lg-8"
                    >
                        <span>
                            Aprenda a liderar equipes de forma eficaz, promovendo comunicação transparente, engajamento e resolução de conflitos à distância. Explore estratégias práticas para motivar colaboradores, gerenciar o tempo de maneira eficiente e cultivar uma cultura organizacional remota inclusiva.
                        </span>
                    </div>
                </div>
                <br />
                <br />
            </div>
        </div>

    );
};

export default BoxVideo;

