// yeshapps/master/Index.js
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Home from '../index';
import Apps from '../apps/index';
import Explorer from '../../../yeshapps/explorer'; // Importe o componente Explore
import ChatRoute from '../../../yeshapps/chatRoute'; // Importe o componente ChatRoute

function MasterRoutes() {
  return (
    <Routes>
      <Route path="" element={<Home />} />
      <Route path="apps" element={<Apps />} />
      {/* Adicione mais rotas para a seção 'master' conforme necessário */}
    </Routes>
  );
}

export default MasterRoutes;
