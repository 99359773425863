import React from 'react';
import ForYouCard from '../forYouCard/index';
import '../css/pageList.css';

const ForYouListPage = ({ appList }) => {
    return (
        <div className="container forYou-container">
            <div className="header-container">
                <h2 className="my-10">Papos com a sua cara</h2>
                <hr className="line" />
            </div>
            <div className="row">
                <ForYouCard appList={appList} />
            </div>
        </div>
    );
}

export default ForYouListPage;