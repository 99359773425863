import React from 'react';
import icon from '../../../../assets/svg/Icon.svg';

const KnowledgeTrails = ({ point }) => {
    return (
        <>
            <div className='row'>
                <div className="col-12 col-md-12 col-lg-6" key={1}>
                    <div className="card card-pass">
                        <div className="card-body box-profile-text-pass">
                            <span>
                                Tem 15 minutos? <br />
                                Assista a um papo!
                            </span>
                            <img src={icon} alt='icon' className="box-profile-text-pass-icon" />
                        </div>
                    </div>
                </div>
                <div className="col-12 col-md-12 col-lg-6" key={2}>
                    <div className="card card-pass">
                        <div className="card-body box-profile-text-pass">
                            <span>
                                Tem 15 minutos? <br />
                                Assista a um papo!
                            </span>
                            <img src={icon} alt='icon' className="box-profile-text-pass-icon" />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default KnowledgeTrails;
