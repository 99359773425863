import React, { useState, useEffect } from 'react';
import { useJwt } from 'react-jwt';
import '../yeshapps/login.css';
import apiClient from '../utils/api/index';
import { APIClientMessage } from "../utils/api/messagereturn/index";
import Login_svg from '../assets/svg/login.svg';
import Logo_svg from '../assets/svg/logo.svg';
const json = {}
const url = window.location.origin;
//console.log(url)

if (url === "http://localhost:3000") {
  json.data = {
    logo: Logo_svg,
    primaryHexadecimal: "#DA3668",
    primaryRGB: "rgb(218, 54, 104)",
    primaryRGBA: "rgba(218, 54, 104, 1)",
  }
} else if (url === 'http://127.0.0.1:3000') {
  json.data = {
    logo: 'https://yesh.com.br/decode/assets/SVGs/LogoYeshBlackVertical.png',
    primaryHexadecimal: "#fef806",
    primaryRGB: "rgb(254, 248, 6)",
    primaryRGBA: "rgb(254, 248, 6,1)",
  }
}

/* const json = {
  data: {
    logo: Logo_svg,
    primaryHexadecimal: "#DA3668"
  }
}; */
function Index({ evt }) {
  const [dataClient, setdataClient] = useState(json);
  const [token, setToken] = useState(sessionStorage.getItem("userToken"));
  const { decodedToken } = useJwt(token);
  const [isLogged, setisLogged] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    apiClient.setAppkey(sessionStorage.getItem("appkey"));
    apiClient.setToken(sessionStorage.getItem("apptoken"));
    apiClient.post("login", { email: email, password: password })
      .then(data => {
        console.log(data.message);
        if (data.message === "successful login") {
          sessionStorage.setItem("userkey", data.data.userKey);
          sessionStorage.setItem("userToken", data.data.token);
          setToken(data.data.token);
          setisLogged(true);
          //console.log(decodedToken);
        }
        APIClientMessage(data);
      })
      .catch(data => {
        apiClient.setAppkey(sessionStorage.removeItem("appkey"));
        apiClient.setToken(sessionStorage.removeItem("apptoken"));
        APIClientMessage(data);
      });
  };

  useEffect(() => {
    //document.documentElement.style.setProperty('--primaryHexadecimal', dataClient?.data.primaryHexadecimal);
    //document.documentElement.style.setProperty('--primaryRGB', dataClient?.data.primaryRGB);
    //document.documentElement.style.setProperty('--primaryRGBA', dataClient?.data.primaryRGBA);
    setdataClient(json)
    //console.log(dataClient);
  }, [dataClient]);

  if (isLogged) {
    evt(true);
  }

  return (
    <section key="Login">
      <div className='row vh-100'>
        <img className='logo' src={Logo_svg} alt="Logo" />
        <div className="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8">
          <div className="container d-flex justify-content-center align-items-center" style={{ height: "100vh" }}>
            <div className='text-center'>
              <h4>Entre na PAPO</h4>
              <div className="card">
                <div className="">
                  <div className='card-body'>
                    <form onSubmit={handleSubmit}>
                      <div className="form-outline mb-4">
                        <input
                          value={email}
                          onChange={handleEmailChange}
                          type="email"
                          className="form-control form-control-lg"
                          placeholder="Endereço de e-mail"
                          required
                        />
                      </div>
                      <div className="form-outline mb-3">
                        <input
                          value={password}
                          onChange={handlePasswordChange}
                          type="password"
                          className="form-control form-control-lg"
                          placeholder="Senha"
                          required
                        />
                      </div>
                      <div className="text-center text-lg-start mt-4 pt-2 d-flex justify-content-center align-items-center">
                        <button type="submit" className="btn btn-primary btn-lg" style={{ paddingLeft: "2.5rem", paddingRight: "2.5rem" }}>Login</button>
                      </div>
                      <div className="d-flex d-flex justify-content-center align-items-center">
                        <a href="#!" className="text-body">Esqueci minha senha</a>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="imgdiv col-md-4 col-lg-4 col-xl-4">
          <img src={Login_svg} className="img-fluid" alt="human" />
        </div>
      </div>
    </section>
  );
}

export default Index;
