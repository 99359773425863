import React, { useEffect, useState } from "react";
import Navbar from "../navbar";
import ProfileCard from "../profileCard";
import ProfileCardTip from "../profileCardTip";
import FormProfile from "../formProfile";
import Loader from '../../../../utils/loader/index';


function ProfileContent(props) {
    const [timer] = useState(2000);
    const [showLoader, setShowLoader] = useState(true);
    const [categories] = useState([
        { "text": "Trabalho Remoto", "color": "#005CE7", "status": true },
        { "text": "Marketing", "color": "#8A3D91", "status": false },
        { "text": "Criatividade", "color": "#96C11A", "status": true },
        { "text": "Bem-estar", "color": "#404040", "status": false },
        { "text": "Gestão", "color": "#5DD0C0", "status": true },
        { "text": "Comunicação", "color": "#E5522D", "status": true },
        { "text": "Complice", "color": "#FBC334", "status": true },
        { "text": "Técnoligia", "color": "#EC55B4", "status": true }
    ]);



    useEffect(() => {
        const timerId = setTimeout(() => {
            setShowLoader(false);
        }, timer);
        // Limpar o timeout quando o componente for desmontado ou quando o timer for alterado
        return () => clearTimeout(timerId);
    }, [timer]);

    return (
        <div className="app">
            <Navbar activeLocation="perfil" />
            <div className="container">
                <div className="row">
                    <div className="col-12 col-sm-12 col-md-4 col-lg-3">
                        {showLoader ? <Loader /> :
                            <ProfileCard
                                photo="http://localhost:3000/static/media/logo.f70d6e50fcf0d8c2cff5a25deb59b923.svg"
                                name="Enzo Souza"
                                role="Community Manager"
                                point={2}
                            />
                        }
                        {showLoader ? <Loader /> : <ProfileCardTip point={2} />}

                    </div>
                    <div className="col-12 col-sm-12 col-md-8 col-lg-9">
                        {showLoader ? <Loader /> :
                            <FormProfile categories={categories} />}
                    </div>
                </div>
            </div>
        </div>
    );

}
export default ProfileContent;