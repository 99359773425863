import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export function APIClientMessage(data) {
  switch (data?.code) {
    case 200:
    case 201:
    case 204:
      return getmsg("success", data.message);
    case 203:
      return getmsg("success", "Excluido com sucesso");
    case 320:
      return getmsg("error", data.message);
    case 400:
    case 401:
    case 404:
    case 500:
      return getmsg("error", data.message);
    default:
      return getmsg("info", "Serviço indisponível no momento");
  }
}

function getmsg(code, message) {
  toast[code](message);
}