import React from 'react';
import Accordion from '../accordion';
import '../css/contentList.css';

const ContentList = () => {
    const selected = "Ep. 02: Comunicação efetiva em ambientes virtuais";
    const topics = [
        {
            title: 'Liderança no Trabalho Remoto',
            contents: [
                {
                    title: 'Content 1', items: [
                        { "text": "Ep. 01: Introdução", "viewed": true },
                        { "text": "Ep. 02: Comunicação efetiva em ambientes virtuais", "viewed": false },
                        { "text": "Ep. 03: Motivação e engajamento da equipe remota", "viewed": false },
                        { "text": "Ep. 04: Gestão de conflitos a distância", "viewed": false },
                        { "text": "Ep. 05: Feedback e avaliação a distância", "viewed": false },
                        { "text": "Ep. 06: Criação de cultura organizacional remota", "viewed": false }
                    ]
                },
            ]
        },
        {
            title: 'Bem-estar e Gestão de Pauta',
            contents: []
        }
    ];




    return (
        <div className='card'>
            <Accordion topics={topics} selected={selected} />
        </div>

    );
}
export default ContentList;