import React, { useState } from 'react';
import '../css/todayCard.css';
import icon from '../../../../assets/svg/Icon.svg';
import next from '../../../../assets/svg/Icon.svg';
import prev from '../../../../assets/svg/iconInvert.svg';
import arrowActive from '../../../../assets/svg/arrow_ativo.svg';
import arrowInactive from '../../../../assets/svg/arrow_inativo.svg';
import check from '../../../../assets/svg/Check.svg';

const TodayCard = ({ todayList }) => {
    const [startIndex, setStartIndex] = useState(0);
    const basePath = '../../../../assets/svg/';
    const handleTodayCardNext = () => {
        console.log("next");
        setStartIndex(prevIndex => Math.min(prevIndex + 1, todayList.length - 3));
    };

    const handleTodayCardPrev = () => {
        console.log("Prev");
        setStartIndex(prevIndex => Math.max(prevIndex - 1, 0));
    };

    return (
        <>
            {todayList ? (
                <>
                    <div className='nav-control'>
                        <div key='todayPrevDiv' className="prevButton" onClick={handleTodayCardPrev} >
                            <img key='todayPrev' src={prev} alt='Voltar' className='icon-nav' style={{ opacity: startIndex > 0 ? '1' : '0' }} />
                        </div>
                        <div key='todayNextDiv' className="nextButton" onClick={handleTodayCardNext} >
                            <img key='todayNext' src={next} alt='Avançar' className='icon-nav' style={{ opacity: startIndex < todayList.length - 3 ? '1' : '0' }} />
                        </div>
                    </div>
                    <div className="row">
                        {todayList.slice(startIndex, startIndex + 3).map((item, index) => (
                            <>
                                <div key={`todayCardRoot${index}`} className="col-12 col-sm-12 col-md-12 col-lg-3" >
                                    <div
                                        className={`card todayCard-card ${item.visto ? 'todayCard-card-ativo' : 'todayCard-card-inativo'}`}
                                        key={`todayCard${index}`}
                                        style={{ backgroundImage: `url(${require(`../../../../assets/svg/${item.backgroundImage}`)})` }}
                                    >
                                        <img src={check} alt='icon' className={item.checked ? 'icon-image_check_active' : 'icon-image_check_inactive'} key={`todayCardRowBodyIcon${index}`} />
                                        <div className='row' key={`todayCardRowCategory${index}`}>
                                            <div className='col-6 todayCard-cardCategory' key={`todayCardCategory${index}`}>
                                                {item.categoria}
                                            </div>
                                        </div>
                                        <div className="card-body todayCard-cardBody" key={`todayCardRowBody${index}`} title={`Como organizar os seus arquivos na rede ${item.appName}`} >
                                            <span key={`todayCardRowDecription${index}`}  >
                                                {`${item.titulo} `}
                                            </span>
                                            <img src={icon} alt='icon' className="icon-image_link" key={`todayCardRowBodyIcon${index}`} />
                                        </div>
                                    </div>
                                </div>
                                {/* Verifica se é o último elemento de todayList antes de renderizar a div */}
                                {startIndex + index !== todayList.length - 1 && (
                                    <div className="col-12 col-sm-12 col-md-12 col-lg-1 todayDiv-progress" key={`nextDiv${index}`}>
                                        <img src={item.prosesseguir ? arrowActive : arrowInactive} alt='Avançar' className='icon-progress' key={`icon-progress${index}`} />
                                    </div>
                                )}
                            </>
                        ))}
                    </div>
                </>
            ) : ""}
        </>
    );
}

export default TodayCard;
