import React from 'react';
import TodayCard from '../todayCard/index';
import '../css/todayCard.css';

const TodayListPage = ({ todayList }) => {
    return (
        <div className="container today-container">
            <div className="header-container">
                <h2 className="my-10">O que tem para hoje?</h2>
                <hr className="line" />
            </div>
            <div className="row">
                <TodayCard todayList={todayList} />
            </div>
        </div>
    );
}

export default TodayListPage;
