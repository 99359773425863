import React from "react";
import { BrowserRouter as Router } from 'react-router-dom';
import AppRoutes from '../routes/index';
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./index.css"


function App() {
  //consumir api
  //retornar empresa
  //retornar json com cores primárias

  return (
    <div>
      <Router>
        <AppRoutes />
      </Router>
      <ToastContainer />
    </div>
  );
}

export default App;