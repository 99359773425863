import React, { useState } from 'react';
import oldIten from '../../../../assets/svg/old-iten.svg';
import newIten from '../../../../assets/svg/new-iten.svg';
import currentIten from '../../../../assets/svg/current-iten.svg'
import '../css/acordion.css';

const Accordion = ({ topics, selected }) => {
    const [activeIndex, setActiveIndex] = useState(0);

    const handleClick = (index) => {
        setActiveIndex(index === activeIndex ? null : index);
    };

    return (
        <div className="accordion">
            {topics.map((topic, index) => (
                <div key={index} className="accordion-item">
                    <button
                        className={`accordion-button ${activeIndex === index ? 'active' : ''}`}
                        onClick={() => handleClick(index)}
                    >
                        {topic.title}
                    </button>
                    {activeIndex === index && (
                        <div className="accordion-content">
                            {topic.contents.map((content, idx) => (
                                <div key={idx} className="accordion-content-item">
                                    {/*<h3>{content.title}</h3>*/}
                                    <div className='list-videos'>
                                        {content.items.map((item, i) => (
                                            <div className={`row item-icon${(item.viewed ? '-cheked' : '')}`} key={i}>
                                                <div className='col-2'>
                                                    <img src={(item.text === selected) ? currentIten : (item.viewed ? oldIten : newIten)} alt='staus icon' />
                                                </div>
                                                <div className='col-9'>
                                                    <span>{item.text}</span>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            ))}
        </div>
    );
};

export default Accordion;
