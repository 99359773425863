import React from 'react';
import '../css/categoryCard.css';


const CategoryCard = ({ categories }) => {
    return (
        <div className='row categoryCardRow'>
            {categories.map((category, index) => (
                <div className="col-12 col-sm-12 col-md-6 col-lg-3 categoryCard" key={`categoryCard${index}`} style={{ backgroundColor: category.color }} >
                    <span className='categoryCardText' key={`categoryCardText${index}`}>{category.text}</span>
                </div>
            ))}
        </div >
    );
};

export default CategoryCard;