import React from 'react';
import KnowledgeTrails from '../knowledgeTrails';
import '../css/todayCard.css';

const KnowledgeTrailsListPage = () => {
    return (
        <div className="container today-container">
            <div className="header-container">
                <h2 className="my-10">Trilhas de conhecimento</h2>
                <hr className="line" />
            </div>
            <div className="row">
                <KnowledgeTrails point={2} />
            </div>
        </div>
    );
}

export default KnowledgeTrailsListPage;
