import React from 'react';
import '../css/pageList.css';
import '../css/formProfile.css';
import '../css/categoryCard.css';

const FormProfile = ({ categories }) => {

    return (
        <div className="container today-container">
            <div className="header-container">
                <h2 className="my-10">Perfil</h2>
                <hr className="line" />
            </div>
            <div className="row">
                <div className="col-12 col-sm-12 col-md-12 col-lg-12 profile-control">
                    <button type="button" className="btn btn-primary">Salvar</button>
                </div>
                <div className="col-12 col-sm-12 col-md-8 col-lg-8">
                    <div className='card formProfileCard'>
                        <form>
                            <br />
                            <div className="form-group row">
                                <div className="col-sm-12">
                                    <input type="text" className="form-control" id="staticName" placeholder="Digite seu nome" />
                                </div>
                            </div>
                            <br />
                            <div className="form-group row">

                                <div className="col-sm-12">
                                    <input type="text" className="form-control" id="staticCargo" placeholder="Digite seu cargo" />
                                </div>
                            </div>
                            <br />
                            <div className="form-group row">
                                <div className="col-sm-12">
                                    <input type="text" className="form-control" id="staticEmail" placeholder="Digite seu e-mail" />
                                </div>
                            </div>
                            <br />
                            <div className="form-group row">
                                <div className="col-sm-12">
                                    <input type="password" className="form-control" id="staticEmail" placeholder="Digite a sua senha" />
                                </div>
                            </div>
                            <br />
                            <div className="form-group row">
                                <div className="col-sm-12">
                                    <input type="password" className="form-control" id="staticEmail" placeholder="Comfirme a sua senha" />
                                </div>
                            </div>
                            <br />
                            <div className='row' >
                                {categories && categories.map((category, index) => {
                                    return (
                                        <div className='col-12 col-sm-12 col-md-6 col-lg-6'>
                                            <div className='row' key={`categoryCard${index}`}>
                                                <div className="col-3 profileStatus" >
                                                    <div class="form-check form-switch">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox" role="switch"
                                                            id={`status${index}`}
                                                            checked={category.status ? true : false}
                                                        />
                                                        <label className="form-check-label" for={`status${index}`} ></label>
                                                    </div>
                                                </div>
                                                <div className="col-9 profileText" style={{ backgroundColor: category.color }}>
                                                    <span className='categoryCardText'>{category.text} {category.status}</span>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                            <br />

                        </form>
                    </div>
                </div>
                <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                    <div className='card' hidden>
                        Page 2
                    </div>
                </div>

            </div>
        </div>
    );

}
export default FormProfile;