import React, { useState, useEffect } from "react";
import Navbar from "../navbar";
import ProfileCard from '../profileCard';
import ContentList from "../contentList";
import BoxVideo from "../boxVideo/index";


import Loader from '../../../../utils/loader/index';

const ChatRouteContent = () => {
    const [timer] = useState(2000);
    const [showLoader, setShowLoader] = useState(true);

    useEffect(() => {

        const timerId = setTimeout(() => {
            setShowLoader(false);
        }, timer);

        // Limpar o timeout quando o componente for desmontado ou quando o timer for alterado
        return () => clearTimeout(timerId);
    }, [timer]);


    return (
        <div className="app">
            <Navbar activeLocation="" />
            <div className="container">
                <div className="row">
                    <div className="col-12 col-sm-12 col-md-4 col-lg-3">
                        {showLoader ? <Loader /> :
                            <ProfileCard
                                photo="http://localhost:3000/static/media/logo.f70d6e50fcf0d8c2cff5a25deb59b923.svg"
                                name="Enzo Souza"
                                role="Community Manager"
                                point={2}
                            />
                        }
                        {showLoader ? <Loader /> :
                            <ContentList />
                        }
                    </div>
                    <div className="col-12 col-sm-12 col-md-8 col-lg-9">
                        {showLoader ? <Loader /> :
                            <BoxVideo />
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ChatRouteContent;