import React, { useRef, useEffect } from 'react';
import Plyr from 'plyr';
import 'plyr/dist/plyr.css';

const PlyrPlayer = ({ source, provider, options }) => {
    const playerRef = useRef(null);
    const player = useRef(null);

    useEffect(() => {
        const initializePlayer = () => {
            if (playerRef.current) {
                try {
                    player.current = new Plyr(playerRef.current, options);

                    // Set player source
                    if (source && provider) {
                        player.current.source = {
                            type: 'video',
                            sources: [{
                                src: source,
                                provider: provider,
                                size: 720, // Set your desired size
                            }],
                        };
                    }
                } catch (error) {
                    console.error('Error initializing Plyr:', error);
                }
            }
        };

        // Call initializePlayer only if playerRef.current is not null and video is ready
        initializePlayer();

        // Cleanup function
        return () => {
            if (player.current) {
                player.current.destroy();
            }
        };
    }, [source, provider, options]);

    return (
        <div>
            <video ref={playerRef} controls>
                <source src={source} type="video/youtube" />
            </video>
        </div>
    );
};

PlyrPlayer.defaultProps = {
    options: {
        controls: [
            'rewind',
            'play',
            'fast-forward',
            'progress',
            'current-time',
            'duration',
            'mute',
            'volume',
            'settings',
            'fullscreen',
        ],
        i18n: {
            restart: 'Restart',
            rewind: 'Rewind {seektime}s',
            play: 'Play',
            pause: 'Pause',
            fastForward: 'Forward {seektime}s',
            seek: 'Seek',
            seekLabel: '{currentTime} of {duration}',
            played: 'Played',
            buffered: 'Buffered',
            currentTime: 'Current time',
            duration: 'Duration',
            volume: 'Volume',
            mute: 'Mute',
            unmute: 'Unmute',
            enableCaptions: 'Enable captions',
            disableCaptions: 'Disable captions',
            download: 'Download',
            enterFullscreen: 'Enter fullscreen',
            exitFullscreen: 'Exit fullscreen',
            frameTitle: 'Player for {title}',
            captions: 'Captions',
            settings: 'Settings',
            menuBack: 'Go back to previous menu',
            speed: 'Speed',
            normal: 'Normal',
            quality: 'Quality',
            loop: 'Loop',
        },
    },
};

export default PlyrPlayer;
