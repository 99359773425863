import React, { useState } from 'react';
//import { Link } from 'react-router-dom';
import icon from '../../../../assets/svg/Icon.svg';
import next from '../../../../assets/svg/Icon.svg';
import prev from '../../../../assets/svg/iconInvert.svg';
import '../css/forYouCard.css';

const ForYouCard = ({ appList }) => {
    const [startIndex, setStartIndex] = useState(0);

    const handleForYouCardNext = () => {
        console.log("next");
        setStartIndex(prevIndex => Math.min(prevIndex + 1, appList.length - 3));
    };

    const handleForYouCardPrev = () => {
        console.log("Prev");
        setStartIndex(prevIndex => Math.max(prevIndex - 1, 0));
    };
    return (
        <>
            <div className='nav-control'>
                <div className="prevButton" onClick={handleForYouCardPrev} >
                    <img key='forYouPrev' src={prev} alt='Voltar' className='icon-nav' style={{ opacity: startIndex > 0 ? '1' : '0' }} />
                </div>
                <div className="nextButton" onClick={handleForYouCardNext} >
                    <img key='forYouNext' src={next} alt='Avançar' className='icon-nav' style={{ opacity: startIndex < appList.length - 4 ? '1' : '0' }} />
                </div>
            </div>
            <div className='row'>
                {appList ? appList.slice(startIndex, startIndex + 4).map((item, index) => (
                    <div className="col-12 col-sm-12 col-md-6 col-xl-3" key={`forYouCardRoot${index}`}>
                        <div className="card forYouCard-card">
                            <div className='row' key={`forYouCardRowCategory${index}`}>
                                <div className='col-6 forYouCard-cardCategory' key={`forYouCardCategory${index}`}>
                                    Onboarding
                                </div>
                            </div>
                            <div className="card-body forYouCard-cardBody" key={`forYouCardRowBody${index}`} title={`Saúde mental no trabalho `} >
                                <span key={`forYouCardRowDecription${index}`}  >
                                    {`Saúde mental no trabalho `}<br />
                                    <span className='forYouCardRowOwner' key={`forYouCardRowOwner${index}`}  >
                                        {`com Drauzio Varela`}
                                    </span>
                                </span>
                                <img src={icon} alt='icon' className="icon-image_link" key={`forYouCardRowBodyIcon${index}`} />
                            </div>
                        </div>
                    </div>
                )) : ""}
            </div>
        </>
    );
}

export default ForYouCard;
