import React, { useState, useEffect } from "react";
import Navbar from "../navbar";
import apiClient from '../../../../utils/api/index';
import ProfileCard from '../profileCard';
import ProfileCardTip from "../profileCardTip";
import KnowledgeTrailsListPage from "../knowledgeTrailsListPage";
import CategoryListPage from "../categoryListPage";
import ForYouListPage from "../forYouListPage";
import Loader from '../../../../utils/loader/index';

const ExplorerContenet = (props) => {
    const [userToken, setUserToken] = useState(null);
    const [userkey, setUserKey] = useState(null);
    const [timer] = useState(2000);
    const [showLoader, setShowLoader] = useState(true);
    const [appList, setappList] = useState(props.props);

    useEffect(() => {

        const timerId = setTimeout(() => {
            setShowLoader(false);
        }, timer);

        // Limpar o timeout quando o componente for desmontado ou quando o timer for alterado
        return () => clearTimeout(timerId);
    }, [timer]);

    useEffect(() => {
        setUserToken(sessionStorage.getItem('userToken') ?? '');
        setUserKey(sessionStorage.getItem('userkey') ?? '');
        apiClient.setAppkey(userkey);
        apiClient.setToken(userToken);
        apiClient.setKeyName('x-userkey');
        apiClient.get('all/apps')
            .then(data => {
                //console.log(data.data);
                setappList(data.data);
            })
            .catch(error => {
                console.error('Error fetching data:', error);
                // Handle error or cleanup as needed
            });
    }, [userToken, userkey]);


    return (
        <div className="app">
            <Navbar activeLocation="explorar" />
            <div className="container">
                <div className="row">
                    <div className="col-12 col-sm-12 col-md-4 col-lg-3">
                        {showLoader ? <Loader /> :
                            <ProfileCard
                                photo="http://localhost:3000/static/media/logo.f70d6e50fcf0d8c2cff5a25deb59b923.svg"
                                name="Enzo Souza"
                                role="Community Manager"
                                point={2}
                            />
                        }
                        {showLoader ? <Loader /> :
                            <ProfileCardTip
                                point={2}
                            />
                        }

                    </div>
                    <div className="col-12 col-sm-12 col-md-8 col-lg-9">
                        {showLoader ? <Loader /> : (appList ? < ForYouListPage appList={appList} /> : "")}
                        {showLoader ? <Loader /> :
                            <KnowledgeTrailsListPage />}
                        {showLoader ? <Loader /> :
                            <CategoryListPage />}
                    </div>
                </div>
            </div>

        </div>
    );
}

export default ExplorerContenet;
