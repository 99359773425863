class APIClient {
  constructor(baseURL) {
    this.baseURL = baseURL;
    this.appkey = null;
    this.token = null;
    this.keyName = 'x-secretkey';
  }

  setAppkey(appkey) {
    this.appkey = appkey;
  }
  setToken(token) {
    this.token = token;
  }
  setKeyName(keyName) {
    this.keyName = keyName;
  }

  async makeRequest(method, endpoint, data = null) {
    const url = `${this.baseURL}/${endpoint}`;

    const headers = {
      [this.keyName]: `${this.appkey}`,
      'authorization': `${this.token}`,
      'Content-Type': 'application/json',
    };

    const requestOptions = {
      method: method,
      headers: headers,
    };

    if (data) {
      requestOptions.body = JSON.stringify(data);
    }

    try {
      const response = await fetch(url, requestOptions);

      if (!response.ok) {
        throw new Error(`Request failed with status ${response.status}`);
      }
      return await response.json();
    } catch (error) {
      throw error;
    }
  }

  get(endpoint) {
    return this.makeRequest('GET', endpoint);
  }

  post(endpoint, data) {
    return this.makeRequest('POST', endpoint, data);
  }

  put(endpoint, data) {
    return this.makeRequest('PUT', endpoint, data);
  }

  patch(endpoint, data) {
    return this.makeRequest('PATCH', endpoint, data);
  }

  delete(endpoint) {
    return this.makeRequest('DELETE', endpoint);
  }
}

export default APIClient;
