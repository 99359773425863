import React, { useState, useEffect } from "react";
import Login from './login';
import ChatRouteContent from "./papo/components/chatRouteContent";
import apiClient from '../utils/api/index'
import { APIClientMessage } from "../utils/api/messagereturn/index"
import { decodeToken } from 'react-jwt';

const appkey = "be759d27fa1246235bcd53a0a6a80a65b265570c0ced3ba1af17a708c1c68160";

const ChatRoute = () => {
    const [isLogged, setisLogged] = useState(false);

    useEffect(() => {
        const checkSessionStorage = () => {
            const userToken = sessionStorage.getItem("userToken");
            const userKey = sessionStorage.getItem("userkey");
            //console.log(`${userKey} : ${userToken} : ${userToken !== null && userKey !== null}`);

            if (userToken !== null && userKey !== null) {
                console.log('Token and key exist');
                try {
                    const decodedToken = decodeToken(userToken);
                    //console.log(decodedToken);
                    const isTokenExpired = new Date(decodedToken.exp * 1000) < new Date();
                    setisLogged(!isTokenExpired);
                } catch (error) {
                    console.log('Error decoding token:', error.message);
                    apiClient.setAppkey(sessionStorage.removeItem("appkey"));
                    apiClient.setToken(sessionStorage.removeItem("apptoken"));
                    setisLogged(false);
                }
            } else {
                console.log('Token or key missing');
                setisLogged(false);
            }
        };

        checkSessionStorage();

        apiClient.setAppkey(appkey);
        apiClient.post("token")
            .then(data => {
                APIClientMessage(data);
                sessionStorage.setItem("appkey", appkey);
                sessionStorage.setItem("apptoken", data.data.token);
                // Remover a linha abaixo para não definir isLogged como true aqui
                // setisLogged(true);
            })
            .catch(error => {
                console.log(error);
            });
    }, []);

    const handleLogin = (evento) => {
        if (evento) {
            setisLogged(evento);
        }
    };

    return (
        <div className="app">
            {isLogged ? (
                <ChatRouteContent />
            ) : (
                <Login evt={handleLogin} />
            )}
        </div>
    );
}

export default ChatRoute;