import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
//import {GetToken} from '../../utils/api/index' 

function Index(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const propsData = location.state;
  const [appList, setappList] = useState(null);
  useEffect(() => {
   // console.log(propsData.key)
    try {
     /*  GetToken(propsData.key)
        .then((ret) => {
          console.log(ret)
          //sessionStorage.setItem("token", ret.data.token);
          
        })
        .catch((error) => {
          console.error("Erro ao obter token:", error);
        }); */
      //setIsExpired(true)
    } catch (error) {
      console.log(error)
    }
  }, []);
  return (

    <>
      <div className='container'>
        Master Start Page
        <br></br>
        <Link to="/master/apps">Apps</Link>
        <br></br>
        <Link onClick={() => navigate(-1)}>Voltar</Link>
      </div>
    </>
  );
}

export default Index;